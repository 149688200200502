<template>
  <div>
    <!-- ************************************************************************************ -->
    <!-- filters area -->
    <!-- ************************************************************************************ -->
    <el-row :gutter="20" type="flex" justify="space-between">
      <el-col :span="8">
        <el-row>
          <el-col :span="12">
            <el-select
              v-model="filters.areaId"
              class="u-bottom-margin--2x"
              :placeholder="$t('topDealsPageFilterLabelArea')"
              clearable
              filterable
              @change="filtersChanged('areaId', $event)"
            >
              <el-option
                v-for="item in areas"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-input
              @change="filtersChanged('productName', $event)"
              :placeholder="$t('topDealsPageFilterLabelSearchProductName')"
              v-model="filters.productName"
              clearable
            ></el-input>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="4">
        <div class="top-deals-page__add-button-holder">
          <el-button
            class="u-bottom-margin--1x"
            type="primary"
            @click="showDialogFlag = true"
          >
            {{ $t("topDealsPageAddTopDealButtonLabel") }}
          </el-button>
        </div>
      </el-col>
    </el-row>

    <!-- ************************************************************************************ -->
    <!-- Sheets handling area -->
    <!-- ************************************************************************************ -->
    <div
      class="u-display-flex u-justify-content--between u-algin-items--center u-bottom-margin--2x"
    >
      <el-upload
        :on-change="uploadSheetFileChanged"
        :on-remove="removeSheetFile"
        action="#"
        :multiple="false"
        :auto-upload="false"
        :file-list="sheetFileList"
        accept=".xlsx, .csv"
      >
        <el-button slot="trigger" size="mini" type="primary">
          {{ $t("topDealsPageSheetButtonLabelSelectFile") }}
        </el-button>
        <el-dropdown size="medium" @command="handleSheetImport">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-top"
            type="info"
            plain
            :disabled="sheetFileList.length == 0"
            :title="sheetFileList.length == 0 ? 'true' : 'false'"
          >
            {{ $t("topDealsPageSheetButtonLabelImport") }}
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="create">{{
              $t("topDealsPageSheetButtonLabelAdd")
            }}</el-dropdown-item>
            <el-dropdown-item command="update">{{
              $t("topDealsPageSheetButtonLabelUpdate")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-upload>
      <div>
        <el-button
          size="mini"
          icon="el-icon-bottom"
          type="info"
          plain
          @click="handleSheetExport('update')"
        >
          {{ $t("topDealsPageSheetButtonLabelExport") }}
        </el-button>
        <el-button
          size="mini"
          icon="el-icon-bottom"
          type="info"
          plain
          @click="handleSheetExport('create')"
        >
          {{ $t("topDealsPageSheetButtonLabelTemplate") }}
        </el-button>
      </div>
      <a style="display: none" ref="sheet-download" target="_blank"></a>
    </div>

    <!-- ************************************************************************************ -->
    <!-- Table Area -->
    <!-- ************************************************************************************ -->
    <template>
      <el-table
        :data="topDealsList"
        border
        stripe
        @select="setSelectedTopDeals"
        @select-all="setSelectedTopDeals"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column
          prop="product.id"
          :label="$t('topDealsPageTableColumnProductId')"
          width="180"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.product.id }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="product.name"
          :label="$t('topDealsPageTableColumnProductName')"
          width="350"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.editFlag">
              {{ scope.row.product.name }}
            </span>
            <el-select
              v-else
              v-model="scope.row.product.id"
              filterable
              remote
              clearable
              reserve-keyword
              :placeholder="$t('topDealsPageTableColumnProductEditLabel')"
              :remote-method="remoteMethod"
              :loading="loading"
            >
              <el-option
                v-for="item in fetchedProducts"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column
          prop="area.id"
          :label="$t('topDealsPageTableColumnAreaId')"
        >
        </el-table-column>
        <el-table-column
          prop="area.name"
          :label="$t('topDealsPageTableColumnAreaName')"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.editFlag">
              {{ scope.row.area.name }}
            </span>
            <el-select
              v-else
              v-model="scope.row.area.id"
              filterable
              reserve-keyword
              :placeholder="$t('topDealsPageTableColumnAreaEditLabel')"
            >
              <el-option
                v-for="item in areas"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="operations"
          :label="$t('topDealsPageTableColumnOperations')"
        >
          <template slot-scope="props">
            <div v-if="!props.row.editFlag">
              <el-button
                type="danger"
                size="mini"
                @click="deleteTopDeal(props.row)"
              >
                {{ $t("topDealsPageTableButtonLabelDelete") }}
              </el-button>
              <el-button
                type="success"
                size="mini"
                @click="editTopDealButtonClicked(props.row)"
              >
                {{ $t("topDealsPageTableButtonLabelEdit") }}
              </el-button>
            </div>
            <div v-else>
              <el-button
                type="primary"
                size="mini"
                @click="editTopDealSave(props.row)"
              >
                {{ $t("topDealsPageTableButtonLabelSave") }}
              </el-button>
              <el-button
                type="danger"
                size="mini"
                @click="cancelEditClicked(props.row)"
              >
                {{ $t("topDealsPageTableButtonLabelCancel") }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>

    <!-- ************************************************************************************ -->
    <!-- Pagination area -->
    <!-- ************************************************************************************ -->
    <div class="u-display-flex u-justify-content--center u-top-padding--2x">
      <el-pagination
        :hide-on-single-page="false"
        background
        layout="prev, pager, next"
        :total="pagination.totalResultsCount"
        :current-page="paginationCurrentPage"
        :page-size="25"
        @current-change="pageChanged"
      ></el-pagination>
    </div>
    <!-- ************************************************************************************ -->
    <!-- Create Dialog area -->
    <!-- ************************************************************************************ -->
    <TopDealsPageDialog
      :showDialog="showDialogFlag"
      @closeDialog="closeDialogClicked"
      @addTopDealClicked="createNewTopDeal"
      :editData="{}"
    />
    <!-- ************************************************************************************ -->
    <!-- Bulk Delete Button Card -->
    <!-- ************************************************************************************ -->

    <el-collapse-transition>
      <div
        class="bulk-action-button-holder"
        v-show="selectedTopDealsForDeletion.length > 0"
      >
        <div class="bulk-action-button-holder__parent">
          <el-button type="danger" size="default" @click="bulkDeleteTopDeals">{{
            $t("topDealsPageBulkDeleteButtonLabel")
          }}</el-button>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>
<script>
import _ from "lodash";

import { topDealsService } from "../../../services/topDeals.service";
import TopDealsPageDialog from "./TopDealsPageDialog.vue";
import { productsService } from "../../../services/products.service";

export default {
  name: "TopDealsPage",
  components: {
    TopDealsPageDialog,
  },
  data() {
    return {
      topDealsList: [],
      pagination: {},
      filters: {},
      showDialogFlag: false,
      loading: false,
      fetchedProducts: [],
      originalTopDeals: [],
      selectedTopDealsForDeletion: [],
      sheetFileList: [],
    };
  },

  methods: {
    async getTopDeals(page = 1, chosenFilters) {
      const filters = chosenFilters;
      this.$loading();
      try {
        const response = await topDealsService.fetchTopDeals(page, filters);
        if (response) {
          let list = response.topDealsList;
          list.forEach((item) => (item.editFlag = false));
          this.originalTopDeals = JSON.parse(JSON.stringify(list));
          this.topDealsList = list;
          this.pagination = response.pagination;
        }
      } catch (error) {
        console.log("error :>> ", error);
        this.topDealsList = [];
        this.originalTopDeals = [];
        this.pagination = {};
      }
      this.$loading().close();
    },
    setSelectedTopDeals(value) {
      this.selectedTopDealsForDeletion = value;
    },
    filtersChanged(key, value) {
      if (!(typeof value === "number") && _.isEmpty(value)) {
        this.filters[key] = undefined;
      }

      if (this.$route.fullPath != "/marketing/topDeals?page=1") {
        this.$router.push({
          query: {
            page: 1,
          },
        });
        return;
      }
      this.getTopDeals(this.currentPage, { ...this.filters });
    },
    pageChanged(pageValue) {
      this.$router.push({
        query: {
          page: pageValue,
        },
      });
    },
    getAreaNameById(areaId) {
      let area = _.find(this.areas, { key: areaId });
      let value = area && area.value ? area.value : "";
      return value;
    },
    closeDialogClicked() {
      this.showDialogFlag = false;
    },
    async createNewTopDeal(newTopDealData) {
      this.$loading();
      this.showDialogFlag = false;
      try {
        const response = await topDealsService.createTopDeal(newTopDealData);
        this.$loading().close();
        if (response) {
          this.$message({
            message: this.$t("topDealsPageCreateNewTopDealSuccessMessage"),
            type: "success",
            duration: 4000,
          });
          await this.getTopDeals(this.currentPage, this.filters);
        }
      } catch (error) {
        this.$loading().close();
        console.log("error :>> ", error);
      }
    },
    async deleteTopDeal(singleTopDeal) {
      this.$confirm(
        this.$t("topDealsPageDeletePopupWarning", [
          singleTopDeal.area.name,
          singleTopDeal.product.name,
        ]),
        "Warning",
        {
          confirmButtonText: this.$t("GlobalOkBtn"),
          cancelButtonText: this.$t("GlobalCancelBtn"),
          type: "warning",
        }
      ).then(async () => {
        this.$loading();
        try {
          const response = await topDealsService.deleteTopDeal(
            singleTopDeal.id
          );
          if (response && response.status == 200) {
            this.$loading().close();
            this.$message({
              message: this.$t(
                "topDealsPageCreateNewTopDealDeleteSuccessMessage"
              ),
              type: "success",
              duration: 3000,
            });
            this.$loading();
            await this.getTopDeals(this.currentPage, this.filters);
          }
        } catch (error) {
          this.$loading().close();
        }
      });
    },
    async remoteMethod(query) {
      if (query !== "") {
        const productFilter = {
          name: query,
        };
        try {
          this.loading = true;
          const response = await productsService.getProducts(productFilter, 1);
          this.fetchedProducts = response.products;
        } catch (error) {
          console.log("error :>> ", error);
        }
        this.loading = false;
      }
    },
    editTopDealSave(clickedRow) {
      const oldRowValue = this.originalTopDeals.filter(
        (item) => item.id == clickedRow.id
      )[0];
      const rowChangedFlag =
        oldRowValue &&
        (clickedRow.area.id != oldRowValue.area.id ||
          clickedRow.product.id != oldRowValue.product.id);
      if (rowChangedFlag) {
        const newAreaName = this.getAreaNameById(clickedRow.area.id);
        const findProduct = this.fetchedProducts.filter(
          (item) => item.id == clickedRow.product.id
        );
        let newProductName =
          findProduct.length > 0
            ? findProduct[0].name
            : clickedRow.product.name;
        this.$confirm(
          this.$t("topDealsPageEditPopupWarning", [
            newAreaName,
            newProductName,
          ]),
          "Warning",
          {
            confirmButtonText: this.$t("GlobalOkBtn"),
            cancelButtonText: this.$t("GlobalCancelBtn"),
            type: "warning",
          }
        ).then(async () => {
          this.$loading();
          try {
            const apiObject = {
              areaId: clickedRow.area.id,
              productId: clickedRow.product.id,
            };
            const response = await topDealsService.editTopDeal(
              clickedRow.id,
              apiObject
            );
            if (response && response.status == 200) {
              this.$loading().close();
              this.$message({
                message: this.$t("topDealsPageEditSuccessMessage"),
                type: "success",
                duration: 3000,
              });
              this.$loading();
              await this.getTopDeals(this.currentPage, this.filters);
            }
          } catch (error) {
            console.log("error :>> ", error);
            this.$loading().close();
          }
        });
      } else {
        this.$message({
          message: this.$t("topDealsPageEditRowNoChangeMessage"),
          type: "error",
          duration: 3000,
        });
      }
    },
    async bulkDeleteTopDeals() {
      let apiIds = [];
      this.selectedTopDealsForDeletion.forEach((singleTopDeal) => {
        apiIds.push(singleTopDeal.id);
      });
      this.$confirm(
        this.$t("topDealsPageBulkDeletePopupWarning", [apiIds.length]),
        "Warning",
        {
          confirmButtonText: this.$t("GlobalOkBtn"),
          cancelButtonText: this.$t("GlobalCancelBtn"),
          type: "warning",
        }
      ).then(async () => {
        this.$loading();
        try {
          const apiObject = {
            ids: apiIds,
          };
          const response = await topDealsService.bulkDeleteTopDeals(apiObject);
          if (response) {
            this.$loading().close();
            let deleteCount = response.deletedTopDeals;
            this.$message({
              message: this.$t("topDealsPageBulkDeleteSuccessMessage", [
                deleteCount,
              ]),
              type: "success",
              duration: 5000,
            });
            this.selectedTopDealsForDeletion = [];
            this.$loading();
            if (this.currentPage > 1) {
              this.$router.push({
                query: {
                  page: this.currentPage - 1,
                },
              });
              return;
            }
            await this.getTopDeals(this.currentPage, this.filters);
          }
          this.$loading().close();
        } catch (error) {
          this.$loading().close();
        }
      });
    },
    editTopDealButtonClicked(clickedRow) {
      this.fetchedProducts = [JSON.parse(JSON.stringify(clickedRow.product))];
      clickedRow.editFlag = true;
    },
    cancelEditClicked(clickedRow) {
      const originalRow = this.originalTopDeals.filter(
        (item) => item.id === clickedRow.id
      );
      if (originalRow && originalRow.length > 0) {
        this.topDealsList = this.topDealsList.map((item) => {
          if (item.id == clickedRow.id) {
            item = JSON.parse(JSON.stringify(originalRow[0]));
            item.editFlag = false;
          }
          return item;
        });
      }
    },
    uploadSheetFileChanged(changedData) {
      this.sheetFileList = [changedData];
    },
    removeSheetFile() {
      this.sheetFileList = [];
    },
    async handleSheetImport(chosenAction) {
      let toBeSentForm = new FormData();
      toBeSentForm.append("file", this.sheetFileList[0].raw);
      this.$loading();
      try {
        const response = await topDealsService.importTopDealsSheet(
          toBeSentForm,
          chosenAction
        );
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            if (parsedResponse.message) {
              this.$message({
                message: parsedResponse.message,
                type: "success",
                duration: 2000,
              });
            } else {
              this.$message({
                message: "Success",
                type: "success",
                duration: 2000,
              });
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            this.$refs["sheet-download"].setAttribute(
              "href",
              window.URL.createObjectURL(blob)
            );
            this.$refs["sheet-download"].setAttribute(
              "download",
              `TopDealsUploadErrorSheet.xlsx`
            );
            this.$refs["sheet-download"].click();
            this.$message({
              message: this.$t("topDealsPageSheetImportFeedbackMessage"),
              type: "success",
              duration: 4000,
            });
          }
        }
        this.$loading().close();
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: this.$t("GlobalErrorMessage"),
          type: "error",
          duration: 2000,
        });
        this.$loading().close();
      }
      this.sheetFileList = [];
    },
    async handleSheetExport(chosenAction) {
      const isCreate = chosenAction === "create";
      let areaId = null;
      // if(!isCreate && !this.filters.areaId){
      //     this.$message({
      //         message: this.$t('topDealsPageExportConditionErrorMessage'),
      //         type: 'error',
      //         duration:4000
      //     });
      //     return
      // }
      areaId = isCreate ? null : this.filters.areaId;
      try {
        this.$loading();
        const response = await topDealsService.exportTopDealsSheet(
          chosenAction,
          areaId
        );
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          this.$refs["sheet-download"].setAttribute(
            "href",
            window.URL.createObjectURL(blob)
          );
          let downloadedFileName = isCreate
            ? "TopDealsTemplate.xlsx"
            : `TopDeals.xlsx`;
          this.$refs["sheet-download"].setAttribute(
            "download",
            downloadedFileName
          );
          this.$refs["sheet-download"].click();
          this.$message({
            message: this.$t("topDealsPageSheetDownloadFeedbackMessage"),
            type: "success",
            duration: 4000,
          });
        }
        this.$loading().close();
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: this.$t("GlobalErrorMessage"),
          type: "error",
          duration: 2000,
        });
        this.$loading().close();
      }
    },
  },
  computed: {
    paginationCurrentPage() {
      return this.pagination.nextPage
        ? this.pagination.nextPage - 1
        : this.pagination.totalPages;
    },
    currentPage() {
      let currentPageQuery = this.$route.query.page;
      return currentPageQuery ? currentPageQuery : 1;
    },
    areas() {
      const currentLookups = this.$store.getters.getAllLookups;
      const currentAreas =
        currentLookups && currentLookups.areas ? currentLookups.areas : [];
      return currentAreas;
    },
  },
  async created() {
    await this.getTopDeals(this.currentPage, this.filters);
  },
  watch: {
    $route(to) {
      this.getTopDeals(to.query.page, this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.top-deals-page {
  &__add-button-holder {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  [dir="rtl"] {
    .status-column__active-label {
      padding-right: 10px;
    }
  }
}
.bulk-action-button-holder {
  position: absolute;
  top: 5vh;
  right: 5vw;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 250px;
  height: 150px;
  &__parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
[dir="rtl"] {
  .bulk-action-button-holder {
    left: 5vw;
  }
}
</style>
