import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function fetchTopDeals(page = 1, filters) {
    return $http
      .get(`/admin/topDeal`, {
        params: { page, filter: filters },
        headers: authHeader(),
      })
      .then((res) => {
            let fetchedData = res.data.data
            return {
                topDealsList: fetchedData.result,
                pagination: {
                    totalPages: fetchedData.pages,
                    totalResultsCount: fetchedData.count,
                    nextPage: fetchedData.next,
                },
            };
      });
  
  }
  
  function createTopDeal(data) {
    return $http
      .post(`/admin/topDeal`,
      data,
      {
        headers: authHeader(),
      })
      .then((res) => {
            let fetchedData = res.data
            console.log('fetchedData :>> ', fetchedData);
            return res;
      });
  
  }
  function deleteTopDeal(topDealId) {
    return $http
      .delete(`/admin/topDeal/${topDealId}`,
      {
        headers: authHeader(),
      })
      .then((res) => {
            let fetchedData = res.data
            console.log('fetchedData :>> ', fetchedData);
            return res;
      });
  }
  function editTopDeal(topDealId,newData) {
    return $http
      .put(`/admin/topDeal/${topDealId}`,
      newData,
      {
        headers: authHeader(),
      })
      .then((res) => {
            let fetchedData = res.data
            console.log('fetchedData :>> ', fetchedData);
            return res;
      });
  }
  function bulkDeleteTopDeals(toBeSentObject) {
    return $http
      .delete(`/admin/topDeal/bulk`,
      {
        data:toBeSentObject,
        headers: authHeader(),
      })
      .then((res) => {
            let fetchedData = res.data.data
            console.log('fetchedData :>> ', fetchedData);
            return fetchedData;
      });
  }

  function importTopDealsSheet(toBeSentFormData,action){
    return $http.post(
      `/admin/topDeal/import?action=${action}`,
      toBeSentFormData,
      {
        headers: authHeader(),
        responseType: "arraybuffer"
      }
    );
  }
  function exportTopDealsSheet(action, areaId){
    let paramsObject = {action}
    if(areaId){
      paramsObject = {...paramsObject, areaId}
    } 
    return $http.get(
      `/admin/topDeal/export`,
      {
        headers: authHeader(),
        responseType: "arraybuffer",
        params: { ...paramsObject }
      }
    );
  }
  
export const topDealsService = {
    fetchTopDeals,
    createTopDeal,
    deleteTopDeal,
    editTopDeal,
    bulkDeleteTopDeals,
    importTopDealsSheet,
    exportTopDealsSheet
};
