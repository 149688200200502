<template>
  <el-dialog
    @close="closeDialog"
    :title="$t('topDealsPageDialogCreateLabel')"
    :visible.sync="internalFlag"
  >
    <el-form
      :rules="rules"
      ref="topDealCreateDialogRef"
      :model="dialogFormData"
    >
      <el-form-item
        :label="$t('topDealsPageDialogFieldLabelProductId')"
        prop="productId"
        label-width="150px"
      >
        <el-select
          v-model="dialogFormData.productId"
          filterable
          remote
          clearable
          reserve-keyword
          :placeholder="$t('topDealsDialogProductSearchPlaceholder')"
          :remote-method="getProductsForDropdown"
          :loading="loading"
        >
          <el-option
            v-for="item in fetchedProducts"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('topDealsPageDialogFieldLabelAreaId')"
        prop="areaId"
        label-width="150px"
      >
        <el-select v-model="dialogFormData.areaId" clearable filterable>
          <el-option
            v-for="item in areas"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">{{
        $t("topDealsPageDialogButtonLabelCancel")
      }}</el-button>
      <el-button type="success" @click="addTopDealClicked">{{
        $t("topDealsPageDialogButtonLabelSave")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { productsService } from "../../../services/products.service";
export default {
  name: "TopDealsPageDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalFlag: false,
      rules: {
        productId: [
          {
            required: true,
            message: this.$t("topDealsPageValidationRuleProductId"),
            trigger: "blur",
          },
        ],
        areaId: [
          {
            required: true,
            message: this.$t("topDealsPageValidationRuleAreaId"),
            trigger: "blur",
          },
        ],
      },
      defaultCreateData: {
        productId: null,
        areaId: null,
      },
      dialogFormData: null,
      fetchedProducts: [],
      productSearchText: null,
      loading: false,
    };
  },
  methods: {
    addTopDealClicked() {
      this.$refs["topDealCreateDialogRef"].validate((isValid) => {
        if (isValid) {
          let finalFormData = JSON.parse(JSON.stringify(this.dialogFormData));
          this.$emit("addTopDealClicked", { ...finalFormData });
          this.dialogFormData = JSON.parse(
            JSON.stringify(this.defaultCreateData)
          );
          this.productSearchText = null;
        }
      });
    },

    closeDialog() {
      this.$emit("closeDialog");
      this.$refs["topDealCreateDialogRef"].resetFields();
      this.dialogFormData = JSON.parse(JSON.stringify(this.defaultCreateData));
    },
    async getProductsForDropdown(text) {
      const productFilter = {
        name: text,
      };
      try {
        this.loading = true;
        const response = await productsService.getProducts(productFilter, 1);
        this.fetchedProducts = response.products;
      } catch (error) {
        console.log("error :>> ", error);
      }
      this.loading = false;
    },
  },
  watch: {
    showDialog(newVal) {
      this.internalFlag = newVal;
    },
  },
  computed: {
    areas() {
      let lookups = this.$store.getters.getAllLookups;
      let currentAreas = lookups && lookups.areas ? lookups.areas : [];
      return currentAreas;
    },
  },
  created() {
    this.dialogFormData = { ...this.defaultCreateData };
  },
};
</script>

<style lang="scss" scoped></style>
